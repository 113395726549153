import {createPortal} from "react-dom"
import "./FirstPlan.scss"

const FirstPlan = ({children}) => {
    return createPortal(
        <div className="firstplan">
            <div className="firstplan__center">
                {children}
            </div>
        </div>
    , document.getElementsByClassName('pageContainer')[0])
}

export default FirstPlan