import "./Loader.scss"
import { Text } from "components/Common/Text"
const Loader = ({text = undefined}) => {
    return(
        <div className="loaderContainer">
            <div className="loader__name">
                {
                    text ?
                    <Text request={text} />
                    :
                    <span>Chargement ...</span>
                }  
            </div>
            <div className="loader">
                <div className="loader__cube">
                </div>
                <div className="loader__cube">
                </div>
            </div>
        </div>
    )
}
export default Loader;