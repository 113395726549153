import { library } from "@fortawesome/fontawesome-svg-core"
import { faSyncAlt, faUser, faLanguage, faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { useLayoutEffect, useState, useRef, useEffect, useCallback} from "react"
import { OptionsItem, LangSelector } from "./OptionItem"
import FirstPlan from "components/Common/FirstPlan"
import { CSSTransition } from "react-transition-group"
import './Options.scss'
import { UserInfos } from "components/Common/Auth"
import {useAPI} from "hooks"

library.add( faSyncAlt, faUser, faLanguage, faSignInAlt)
const Options = ({login, onUserClick, isDeployed }) => {
    const [height, setHeight] = useState('auto')
    const node = useRef(null)
    const tempHeight = useRef(0)
    useLayoutEffect(() => {
        tempHeight.current = node.current.getBoundingClientRect().height
    }, [])
    useEffect(() => {
        if (isDeployed){
            setHeight(tempHeight.current)
        }
        else{
            setHeight(0)
        }
    }, [isDeployed])

    const [showUserInfos, setUserInfos] = useState(false)

    const handleUserInfo = useCallback(() => {
        onUserClick()
        setUserInfos(!showUserInfos)
    }, [showUserInfos, onUserClick])
    
    const {fetchData: disconnectCallback, sendRequest: disconnect} = useAPI("controller=auth&request=disconnect", "POST")

    const handleDisconnect = () => {
        window.sessionStorage.removeItem('userData')
        document.cookie = 'auth-token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        disconnect({"login": login})
    }

    useEffect(() => {
        if (disconnectCallback.state !== 'waiting'){
            window.location.reload()
        }
    }, [disconnectCallback])

    return (
        <>
        <div className="options" ref={node} style={{maxHeight: height}}>
            <OptionsItem request="header.options.usersSettings" icon="user" event={handleUserInfo} />
            <LangSelector />
            <OptionsItem request="header.options.disconnect" icon="sign-in-alt" event={handleDisconnect} />
        </div>
        <CSSTransition in={showUserInfos} timeout={200} unmountOnExit classNames='firstplan-anim'>
            <FirstPlan>
                <UserInfos />
            </FirstPlan>
        </CSSTransition>
        </>
    )
}

export default Options 