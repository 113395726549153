const LangSLItem = ({name, file, flag, onLangChange}) => {
    return(
        <div className="langList__item" onClick={() => onLangChange(file)}>
            <div className="langList__item--flag">
                <img alt="" src={flag} />
            </div>
            <div className="langList__item--text">
                <span>{name}</span>
            </div>
        </div>
    )
}

export default LangSLItem