import Logo from "assets/Logo/dark_leaves.png"
import { Text } from "components/Common/Text"
import { Error } from "components/Common/StateIcon"
import "./AccessDenied.scss"
const AccessDenied = () => {
    return(
        <div className="accessDenied">
            <div className="accessDenied__img">
                <img alt="" src={Logo} />
            </div>
            <div className="accessDenied__text">
                <Error />
                <div className="accessDenied__text--text">
                    <Text request="auth.accessdenied" />
                </div>
                <Error />
            </div>
        </div>
    )
}

export default AccessDenied