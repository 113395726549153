import { Text } from "components/Common/Text"
import {Link} from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SubLink from "./SubLink"
const NavLink = ({children, name, link, icon}) => {
    return(
        <>
        {
            children === false ?
                <div className="navigation__simpleLink navigation__link">
                        <Link to={link}>
                            <div className="navigation__link--main">
                                {icon && 
                                <div className="navigation__link--main--icon">
                                    <FontAwesomeIcon fixedWidth icon={icon} />
                                </div> }
                                <div className="navigation__link--main--text">
                                    <Text request={"pathname." + name} />
                                </div>
                            </div>
                        </Link>
                </div>
            :
            <SubLink icon={icon} children={children} link={link} name={name} />
        }
        </>
    )
}

export default NavLink