import { Text } from "components/Common/Text"
import './SavedDataState.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave } from "@fortawesome/free-regular-svg-icons"  
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/index"
import { faExclamationTriangle } from "../../../../../node_modules/@fortawesome/free-solid-svg-icons/index"
const SavedDataState = forwardRef(({button = true}, ref) => {

    const [data, setData] = useState({
        "inchange": [],
        "insave": [],
    })

    const handleInChange = useCallback((name) => {
        if (!data.inchange.includes(name)){
            setData({
                "inchange": [name, ...data.inchange],
                "insave": [...data.insave]
            })
            
        }
    }, [data])

    const handleInSave = useCallback((name) => {
        if (data.inchange.includes(name)){
            setData({
                "insave": [name, ...data.insave],
                "inchange": data.inchange.filter(e => e !== name)
            })
        }
    }, [data])

    const handleSave = useCallback((name) => {
        if (data.insave.includes(name)){
            setData({
                "inchange": data.inchange,
                "insave": data.insave.filter(e => e !== name)
            })
        }
    }, [data])


    useImperativeHandle(
        ref,
        () => ({
            addInChange(name) {handleInChange(name)},
            addInSave(name) {handleInSave(name)},
            addSaved(name) {handleSave(name)},

        }),
    [handleInChange, handleInSave, handleSave])



    const [state, setState] = useState(1)

    useEffect(() => {
        if (data.inchange.length === 0 && data.insave.length === 0){
            setState(1)
        }
        else if (data.inchange.length !== 0 && data.insave.length === 0){
            setState(2)
        }
        else if (data.insave.length !== 0){
            setState(3)
        }
        else{
            setState(4)
        }
    }, [data])

    return(
        <div className="savedData" >
            <div className="savedData__state">
                <div className="savedData__state--icon">

                </div>
                <div className="savedData__state--text" onClick={() => handleInSave('category')}>
                    {
                        state === 1 ?
                            <>
                                <FontAwesomeIcon icon={faCheckCircle} className="savedData__state--text--icon green" />
                                <Text request="common.savedatastate.saved" />
                            </>
                            :
                            state === 0 ?
                                <Text request="common.savedatastate.unsaved" />
                                :
                                state === 2 ?
                                    <Text request="common.savedatastate.writing" />
                                    :
                                    state === 3 ?
                                    <>
                                        <FontAwesomeIcon icon={faExclamationTriangle} className="savedData__state--text--icon yellow" />
                                        <Text request="common.savedatastate.insave" />
                                    </>
                                        :
                                            <Text request="common.savedatastate.unknow" />
                    }
                </div>
            </div>
            {
                button &&
                <div className="savedData__saveButton">
                    <div className="savedData__saveButton--round">
                        <FontAwesomeIcon icon={faSave} fixedWidth />
                    </div>
                </div>
            }
        </div>
    )
})

export default SavedDataState