import {APIContext} from "App.jsx"
import { useContext, useState } from "react";

const useAPI = ( url, method = "GET", type = "text") => {
    const {apiURL, handleNewPopup} = useContext(APIContext);

    const [fetchData, setData] = useState({
        "state": "waiting",
        "response_code_client": undefined
    })


    const sendRequest = async (body) => {
        await fetch(apiURL + url, {
            method: method,
            credentials: "include",
            body: type === "text" ? JSON.stringify(body) : body,
        })
        .then(
            function(response) {
                if (response.status !== 200) {
                    handleNewPopup("error", "Erreur Code " + response.status, true);
                    setData({
                        "state": "error",
                        "response_code_client": response.status
                    });
                    handleNewPopup("error", "no-permission");
                return;
                }
                response.json().then(function(data) {
                    if (data.response_code === 404){
                        handleNewPopup("error", "Erreur 404, requête introuvable", true);
                    }
                    setData({
                        "state": "success",
                        "response_code_client": response.status,
                        ...data
                    });
                    if (data.cookies){
                        data.cookies.forEach(element => {
                            document.cookie = element.name + "=" + element.value + ";domain=" + (document.location.host === "localhost:8080" ? "localhost" : ".visagesdejeunes.fr") + ";path=/;max-age=" + element.expire + ";samesite=none;secure=true";
                        });
                    }
                    if (data.authorization === false){
                        handleNewPopup("error", "no-permission");
                    }
                });
            }
        )
        .catch(function(err) {
            setData({
                "state": "error",
                "response_code_client": err
            })
        });
    }

    return {fetchData, sendRequest}
}

export default useAPI;