import React, {useCallback, useEffect, useRef, useState} from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import Routes from "./routes"
import './App.scss';
import { Header } from 'components/Common/Header';
import { Navigation } from "components/Common/Navigation"
import { Popup } from 'components/Common/Popup';
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons"
import {library} from "@fortawesome/fontawesome-svg-core"
library.add(faArrowLeft)


const apiURL = {
  dev: "http://localhost/?appname=admin&",
  production: "https://api.visagesdejeunes.fr/?appname=admin&"
}

const userInfo = {
    "data": {
      "login": undefined,
      "id": undefined,
      "permissions": undefined
    },
    "lang": "fr"
}

export const APIContext = React.createContext(apiURL.production)
export const UserContext = React.createContext( JSON.parse(sessionStorage.getItem('userData')) || userInfo)
export const HudContext = React.createContext( "" )

function App() {
    const [lang, setLang] = useState(localStorage.getItem("lang") || "fr")
    const [data, setUserData] = useState(JSON.parse(sessionStorage.getItem('userData')) || userInfo.data)

    const handleUserDataChange = useCallback((newdata) => {
      if (newdata.login !== data.login && newdata.permissions !== data.permissions){
        setUserData(newdata)
      }
      else if (newdata.login !== data.login){
        setUserData({...data, "login": newdata.login})
      }
      else if (newdata.permissions !== data.permissions){
        setUserData({...data, "login": newdata.login})
      }
        
    }, [data])

    useEffect(() => {
      sessionStorage.setItem('userData', JSON.stringify(data))
    }, [data])

    useEffect(() => {
      localStorage.setItem('lang', lang)
    }, [lang])

    const [isNavDeployed, setNavIsDeployed] = useState(data.login ? true : false)

    const handleNavChange = useCallback((value = undefined) => {
        if (typeof value === 'boolean'){
            setNavIsDeployed(value)
        }
        else{
            setNavIsDeployed(!isNavDeployed)
        }
        
    },[isNavDeployed])

    const [isHeaderDeployed, setHeaderDeployed] = useState(true)

    const handleHeaderChange = useCallback((value) => {
      setHeaderDeployed(value)
    },[])

    const handleUserLangChange = useCallback((l) => {
      setLang(l)
    },[])

    /*Popup */

    const handleNewPopup = useCallback((type, message, isMessageCustom) => {
      popupRef.current.addPopup(type, message, isMessageCustom)
    },[])

    const popupRef = useRef()

    return (
      <Router>
          <APIContext.Provider value={{apiURL: (document.location.origin === "https://localhost:8080" ? apiURL.dev : apiURL.production), handleNewPopup}}>
            <UserContext.Provider value={{lang, data, setLang, handleUserDataChange, handleUserLangChange}}>
                <HudContext.Provider value={{handleHeaderChange, handleNavChange}} >
                    <div className="app">
                    <Header onSwitch={handleNavChange} isDeployed={isHeaderDeployed} isNavDeployed={isNavDeployed} />
                    <div className="mainContent">
                        <Navigation isDeployed={isNavDeployed}/>
                        <div className="pageContainer">
                            <Routes />
                        </div>
                    </div>
                    <Popup ref={popupRef} />
                    </div>
                    </HudContext.Provider>
            </UserContext.Provider>
          </APIContext.Provider>
      </Router>
    );
}

export default App;
