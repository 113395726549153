import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-regular-svg-icons'
import { faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core"
import { Text } from "components/Common/Text"
import { UserContext } from "App.jsx"
import { useCallback, useContext, useState } from "react"
import Options from "./Options"

library.add(faUser, faTimes)

const UserPart = () => {

    const { data : {login} } = useContext(UserContext)

    const [optionsDeployed, setOptionsDeployed] = useState(false)

    const handleOptions = useCallback(() => {
        setOptionsDeployed(!optionsDeployed)
    }, [optionsDeployed])

    return(
        <>
        <div className="userPart">
            <div className="userPart__info">
                <div className="userPart__info--name">
                    <span>{login ? login : <Text request={"header.no-connection"} />}</span>
                </div>
                {login &&
                    <div className={"userPart__info--arrow" + (optionsDeployed ? " active" : " notActive")}>
                        <FontAwesomeIcon icon={faCaretDown} onClick={handleOptions} />
                    </div>
                }
            </div>
            <div className="userPart__icon" >
                <FontAwesomeIcon icon="user" />
            </div>
        </div>
        {login &&
            <Options login={login} onUserClick={handleOptions} isDeployed={optionsDeployed}/>
        }
        </>
    )
}

export default UserPart