import { Suspense, useMemo} from 'react'
import {Route, Switch} from 'react-router-dom'
import {CSSTransition, TransitionGroup} from 'react-transition-group'
import { PageLoader } from "./components/Common/Loading"
import RoutesList from "assets/routes.json"
import RoutesTreatment from "./routesTreatment"
const Routes = () => {

    const flatRoutes = useMemo(() => (
        JSON.parse(JSON.stringify(RoutesList)).flatMap((e) => {
            if (e.sub !== undefined){
                const subArray = e.sub
                delete e.sub
                return [e, subArray].flat()
            }
            return e;
        })
    ), [])
    
    return(
        <Suspense fallback={<PageLoader />}>
            <Route render={({location}) => (
                <TransitionGroup component={null} >
                    <CSSTransition key={location.key} timeout={300} classNames="page-anim">
                        <Switch location={location}>
                            {
                               flatRoutes.map((route) => (
                                    <Route key={route.path} exact={true} path={route.path}>
                                            <RoutesTreatment {...route} />
                                    </Route>
                                ))
                            }
                            <Route path='*'>
                                <span>Erreur 404, page introuvable</span>
                            </Route>
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            )}
            />
        </Suspense>
    )
}

export default Routes;