import { AuthChecker } from "./components/Common/Auth"
import {lazy, createElement, useContext, useEffect} from "react"
import {HudContext} from "./App"
import {useTitle} from "hooks"
const Dashboard = lazy(() => import("components/Routes/Dashboard"))
const Members = lazy(() => import("components/Routes/Members"))
const PublicMembers = lazy(() => import("components/Routes/PublicMembers"))
const WDD = lazy(() => import("components/Routes/WDD"))
const Informations = lazy(() => import("components/Routes/Informations"))
const Background = lazy(() => import("components/Routes/Background"))
const Actions = lazy(() => import("components/Routes/Actions"))
const Register = lazy(() => import("components/Routes/Register"))
const ComponentsList = {
    Dashboard,
    Members,
    WDD,
    Informations,
    Background,
    Actions,
    Register,
    PublicMembers
}

const RoutesTreatment = ({name, permission, Component, navbarHidden, headerHidden, noPadding, needRegister = true}) => {

    const {handleHeaderChange, handleNavChange} = useContext(HudContext)

    const {sendTitleRequest} = useTitle()
    
    useEffect(() => {
        if (navbarHidden === true){
            handleNavChange(false)
        }
        else{
            handleNavChange(true)
        }
        if (headerHidden){
            handleHeaderChange(false)
        }
        else{
            handleHeaderChange(true)
        }
        sendTitleRequest("pathname." + name)
        //eslint-disable-next-line
    }, [])

    return(
        <div className={"page " + name + "Container" + (noPadding ? " noPadding" : "")}>
            {
                needRegister ?
                    <AuthChecker name={name} permission={permission}>
                        {createElement(ComponentsList[Component])}
                    </AuthChecker>
                :
                createElement(ComponentsList[Component])
            }
        </div>
    )
}

export default RoutesTreatment