import { Text } from 'components/Common/Text'
import {useCallback, useState} from "react"
import {Link} from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

const SubLink = ({name, link, children, icon}) => {
    const [isDeployed, setIsDeployed] = useState(false)

    const handleDeployClick = useCallback(() => {
        setIsDeployed(!isDeployed)
    }, [isDeployed])

    return(
        <div className={"navigation__extendedLink navigation__link" + (isDeployed ? " deployed" :  " hidden")}>
            <div className="navigation__extendedLink--title">
                <div className="navigation__extendedLink--title--text">
                    <Link to={link} >
                        <div className="navigation__link--main">
                            {icon && 
                            <div className="navigation__link--main--icon">
                                <FontAwesomeIcon icon={icon} />
                            </div> }
                            <div className="navigation__link--main--text">
                                <Text request={"pathname." + name} />
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="navigation__extendedLink--title--icon" onClick={handleDeployClick}>
                    <FontAwesomeIcon icon={faAngleDown} />
                </div>
            </div>
            <div className="navigation__extendedLink--sublink">
                {children}
            </div>
        </div>
    )
}

export default SubLink