import './NavIcon.scss'
const NavIcon = ({isNavDeployed}) => {

    return(
        <div className={"navIcon" + (isNavDeployed ? " active" : "")}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

export default NavIcon;