const JSONPath = (path, array) => {
    if (typeof path === "string"){
        return path.split('.').reduce(function(o, k) {
            return o && o[k];
        }, array);
    }
    console.error("Utils JSONPath -> Path is not a string")
    return null;
}

export default JSONPath;