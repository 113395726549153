import {useIsMobile} from "hooks"
import {MainHeader} from "./MainHeader"
const Header = ({onSwitch, isNavDeployed, isDeployed}) => {

    const isMobile = useIsMobile()

    return(
        <>
        {
            isMobile ?
            <span>Mobile</span>
            :
            <MainHeader onSwitch={onSwitch} isNavDeployed={isNavDeployed} isDeployed={isDeployed} />
        }
        </>
    )
}

export {Header}