import { FR, ES, EN} from "assets/flags/"
import LangSLItem from "./LangSLItem"
import './LangSelectorList.scss'
import { UserContext } from "App.jsx"
import { useEffect, useRef, useState, useLayoutEffect, useCallback, useContext } from "react"
const LangSelectorList = ({isDeployed, hide}) => {
    const [height, setHeight] = useState('auto')
    const node = useRef(null)
    const tempHeight = useRef(0)
    useLayoutEffect(() => {
        tempHeight.current = node.current.getBoundingClientRect().height
    }, [])
    useEffect(() => {
        if (isDeployed){
            setHeight(tempHeight.current)
        }
        else{
            setHeight(0)
        }
    }, [isDeployed])

    const { lang, handleUserLangChange } = useContext(UserContext)

    const handleLangChange = useCallback((l) => {
        if (lang !== l){
            handleUserLangChange(l)
        }
        hide()
    }, [handleUserLangChange, lang, hide])

    return(
        <div className="langList" ref={node} style={{maxHeight: height}}>
            <LangSLItem name="Français" file='fr' flag={FR} onLangChange={handleLangChange} />
            <LangSLItem name="English" file='en' flag={EN} onLangChange={handleLangChange} />
            <LangSLItem name="Español" file='es' flag={ES} onLangChange={handleLangChange} />
        </div>
    )
}

export default LangSelectorList;