import { Text } from "components/Common/Text"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UserContext } from "App.jsx"
import { useContext, useState } from "react"
import { FR, ES, EN} from "assets/flags/"
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import LangSelectorList from "./LangSelectorList"
import "./LangSelector.scss"
import { CSSTransition } from "react-transition-group"

const LangSelector = () => {
    const { lang } = useContext(UserContext)
    const [selectorDeployed, setSelectorDeployed] = useState(false)

    const handleSelectorClick = () => {
        setSelectorDeployed(!selectorDeployed)
    }

    return(
        <div className="options__item lang__noClick" onClick={() => console.log("lang")}>
            <div className="options__item--icon">
                <FontAwesomeIcon icon="language" />
            </div>
            <div className="options__item--text lang">
                <Text request="header.options.usersLanguage" />
                <div className="lang__svg">
                        {
                            lang === "fr" ?
                                <img alt={lang} src={FR} />
                                :
                                lang === "en" ?
                                    <img alt={lang} src={EN} />
                                    :
                                    lang === "es" ?
                                        <img alt={lang} src={ES} />
                                        :
                                        <img alt={lang} src={FR} />
                        }
                    </div>
                <div className="lang__arrow">
                    <FontAwesomeIcon icon={faCaretDown} onClick={handleSelectorClick} />
                </div>
                <CSSTransition in={selectorDeployed} unmountOnExit={false} timeout={300}>
                    <LangSelectorList isDeployed={selectorDeployed} hide={() => setSelectorDeployed(false)}/>
                </CSSTransition>
            </div>
        </div>
    )
}

export {LangSelector}