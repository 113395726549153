import "./MainHeader.scss"
import NavIcon from "../NavIcon"
import UserPart from "./UserPart"

const MainHeader = ({onSwitch, isNavDeployed, isDeployed}) => {

    return(
        <div className={"header" + (isDeployed ? "" : " hidden")}>
            <div className="flex">
                <div className="navigationButton" onClick={onSwitch}>
                    <NavIcon isNavDeployed={isNavDeployed} />
                </div>
            </div>
            <div className="flex">
                <UserPart />
            </div>
        </div>
    )
}

export {MainHeader}