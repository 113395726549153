import { Text } from "components/Common/Text"
import { createElement, useMemo, useState } from "react"
import Infos from "./Infos"
import "./UserInfos.scss"

const UserInfos = () => {

    const componentsList = useMemo(() => ( {
        "infos": Infos
    }), [])

    const [component] = useState("infos")
    return(
        <div className="userInfos">
            <div className="userInfos__title">
                <Text request='auth.infos' />
            </div>
            <div className="userInfos__content">
                {createElement(componentsList[component])}
            </div>
        </div>
    )
}

export default UserInfos