import {UserContext} from "App.jsx"
import {useState, useContext, useEffect} from "react"
import JSONPath from "utils/JSONPath.js"

const useText = (request) => {
    const { lang } = useContext(UserContext)
    const [text, setText] = useState(null)

    useEffect(() => {
        import('assets/text/' + lang + '.json').then(textData => {
            var query = JSONPath(request, textData)
            if (query === undefined){
                import('assets/text/fr.json').then(textData => {
                    setText(JSONPath(request, textData))
                })
            }
            else{
                setText(query)
            }
            
        })
    },[lang, request])

    return text;
}

export default useText;