import {APIContext} from "App.jsx"
import { useContext, useState } from "react";

const usePerms = () => {
    const {apiURL} = useContext(APIContext)

    const [ServerPerms, setServerPerms] = useState({
        "authorization": "waiting",
        "response_code_client": undefined
    })
    const sendPermsRequest = async (permname = "") => {
        await fetch(apiURL + "controller=auth&request=checkperms", {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({"permname": permname})
        })
        .then(
            function(response) {
                if (response.status !== 200) {
                    setServerPerms({
                        "state": "error",
                        "response_code_client": response.status
                    })
                return;
                }
                response.json().then(function(data) {
                    setServerPerms({
                        "authorization": data.authorization,
                        "response_code_client": response.status
                    })
                });
            }
        )
        .catch(function(err) {
            setServerPerms({
                "state": "error",
                "response_code_client": err
            })
        });
    }
    return {ServerPerms, sendPermsRequest}
}

export default usePerms;