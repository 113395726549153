import {UserContext} from "App.jsx"
import { useContext, useEffect,useLayoutEffect, useState } from "react"
import JSONPath from "utils/JSONPath.js"
import List from "components/Common/Text/List"
import defaultText from 'assets/text/fr.json'
const Text = ({request = "", balise = true, callback = undefined}) => {
    const { lang } = useContext(UserContext)
    const [text, setText] = useState(null)

    useLayoutEffect(() => {
        setTimeout(() => {
            if (typeof callback === "function" && text != null){
                console.log(text)
                callback()
            }
        }, 5)
    }, [text, callback])

    useEffect(() => {
        import('assets/text/' + lang + '.json').then(textData => {
            var query = JSONPath(request, textData)
            if (query === undefined){
                    setText(JSONPath(request, defaultText))
            }
            else{
                setText(query)
            }
            
        })
    },[lang, request, callback])
    
    
    return(
        <>{Array.isArray(text) ?
            <List list={text} />
            :
            balise ?
                <span>{text}</span>
                :
                text
        }
        </>
    )
}

export default Text;