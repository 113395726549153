import { useState } from "react";
import "./Input.scss"
const Input = ({placeholder = "", name, type = "text", required = false, onValueChange = null, autocorrect = "on"}) => {

    const [value, setValue] = useState("")

    const handleChange = (e) => {
        setValue(e.target.value)
        onValueChange(e.target.value, name)
    }

    return(
        <>
        {
            type === "submit" ?
                <div className="input submit" onClick={onValueChange}>
                    <input type="submit" value={placeholder || ""}></input>
                </div>
                :
                <div className="input">
                {
                    type === "textarea" ?
                    <textarea spellCheck={autocorrect} placeholder={placeholder} value={value} onChange={handleChange} required={required} ></textarea>
                    :
                    <input autoCorrect={autocorrect} type={type} placeholder={placeholder} value={value} onChange={handleChange} required={required}/>
                }
                </div>
        }
        </>
        
    )
}

export default Input;