import { Input } from "components/Common/Auth"
import { useAPI, useText } from "hooks"
import { useEffect, useReducer, useRef, useState, useCallback } from "react"
import { Text } from "components/Common/Text"
import { Success, Error} from "components/Common/StateIcon"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import "./AuthInterface.scss"

const AuthInterface = ({onConnect}) => {
    const [message, setMessage] = useReducer(useMessage, "")

    const data = useRef({
        "vdjlogin": undefined,
        "vdjpassword": undefined,
        "vdjstayconnected": false
    })

    const handleChange = (value, inputID) => {
        data.current[inputID] = value
    }

    const {fetchData, sendRequest} = useAPI("controller=auth&request=connect", "POST")

    const handleSend = (e) => {
        e.preventDefault();
        const body = {
            "login": data.current.vdjlogin,
            "password": data.current.vdjpassword,
            "rememberme": data.current.vdjstayconnected
        };
        sendRequest(body)
    }



    useEffect(() => {
        if (fetchData.state !== "waiting"){
            if (fetchData.response_code_client === 200){
                if (fetchData.response_code === 200){
                    if (fetchData.data.success === true){
                        setMessage('success')
                        onConnect(fetchData.data)
                    }
                    else if (fetchData.data.success === false){
                        setMessage(fetchData.data.error)
                    }
                }
                else{
                    setMessage('error', fetchData.response_code)
                }
            }
            else{
                setMessage('error', fetchData.response_code_client)
            }
        }
    }, [fetchData, onConnect])

    const [showPassword, setShowPassword] = useState(false)

    const handleShowPassword = useCallback(() => {
        setShowPassword(!showPassword)
    }, [showPassword])
    const loginPlaceholder = useText("connection.login-placeholder");
    const passwordPlaceholder = useText("connection.password-placeholder");
    const connectionPlaceholder = useText("connection.submit");
    return(
        <div className="authInterface">
                <form onSubmit={handleSend}>
                <Input autocorrect="off" placeholder={loginPlaceholder} name="vdjlogin" required onValueChange={handleChange}/>
                <div className="authInterface__password">
                <Input placeholder={passwordPlaceholder} name="vdjpassword" type={showPassword ? "text" : "password"} required onValueChange={handleChange}/>
                    <div className="authInterface__password--button" onClick={handleShowPassword}>
                        {
                            showPassword ?
                                <FontAwesomeIcon icon={faEyeSlash} />
                            :
                                <FontAwesomeIcon icon={faEye} />
                        }
                    </div>
                </div>
                <div className="authInterface__stayConnected">
                        <input type="checkbox" id="stayConnected" onChange={() => handleChange(!data.current.vdjstayconnected, "vdjstayconnected")} /><label htmlFor="stayConnected"><Text balise={false} request="connection.stayConnected"/></label>
                </div>
                <Input placeholder={connectionPlaceholder} type="submit"/>
                <div className={"authInterface__result" + (fetchData.state === "waiting" ? " hidden" : "")}>{message}</div>
                </form>
        </div>
    )
}

const useMessage = (state, action, code = "0X0") => {
    switch (action) {
        case "success":
            return (<Success />)    
        case "no login":
            return (<><Error /><div className="authInterface__result--text"><Text request={"connection.error.no-login"} /></div><Error /></>)    
        case "no password":
            return (<><Error /><div className="authInterface__result--text"><Text request={"connection.error.no-password"} /></div><Error /></>)    
        case "wrong password":
            return (<><Error /><div className="authInterface__result--text"><Text request={"connection.error.wrong-password"} /></div><Error /></>)
        case "error":
                return (<><Error /><div className="authInterface__result--text"><span><Text request={"connection.error.error"} balise={false} /> {code}</span></div><Error /></>)
        default:
            return {state}
    }
}


export default AuthInterface