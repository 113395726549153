import "./Loader.scss"
const Loader = () => {
    return(
        <div className="loader">
            <div className="loader__cube">
            </div>
            <div className="loader__cube">
            </div>
        </div>
    )
}
export default Loader;