import { Text } from "components/Common/Text"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LangSelector } from "./LangSelector"
const OptionsItem = ({request, icon, event}) => {
    return(
        <div className="options__item" onClick={event}>
            <div className="options__item--icon">
                <FontAwesomeIcon icon={icon} />
            </div>
            <div className="options__item--text">
                <Text request={request} />
            </div>
        </div>
    )
}

export { OptionsItem, LangSelector }