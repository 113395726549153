import { useCallback, useContext, useEffect } from "react";
import { useAPI, usePerms} from "hooks/index"
import { AuthInterface, AccessDenied } from "components/Common/Auth";
import { PageLoader } from "components/Common/Loading"
import getCookie from "utils/getCookie"
import { UserContext } from "App";

const AuthChecker = ({children, permission = null}) => {

    const { data: {login, permissions}, handleUserDataChange } = useContext(UserContext)

    const {ServerPerms, sendPermsRequest} = usePerms()
    const {fetchData: isAuth, sendRequest: checkIsAuth} = useAPI("controller=auth&request=checktoken", "POST")

    const handleConnect = useCallback((userData) => {
        const userPerms = (userData.permissions !== "" ? JSON.parse(userData.permissions) : [])
        const dataToSend = {
            "login": userData.login,
            "permissions": userPerms
        }
        handleUserDataChange(dataToSend)
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isAuth.data !== undefined){
            if (isAuth.data.success === true){
                handleConnect(isAuth.data)
            }
        }
        //eslint-disable-next-line
    },[isAuth])

    useEffect(() => {
        if (login === undefined){
            if (getCookie("auth-token") !== null && getCookie("auth-token") !== ""){
                checkIsAuth();
            }
            
        }
        if ((login !== undefined) && (permissions.includes(permission) || permission === null)){
            sendPermsRequest(permission)
        }
        //eslint-disable-next-line
    }, [login])

    return(
        <>
            {
                login !== undefined ? // Il est connecté
                    permissions.includes(permission) || permission === null ? //Il possède la permission coté client
                        ServerPerms.authorization === "waiting" ?
                            <PageLoader text="auth.waitforperm"/> // En attente du serveur
                        :
                        ServerPerms.authorization === true ? // Le serveur donne l'autorisation
                            children
                            :
                            <AccessDenied />
                    :
                    <AccessDenied />
                :
                getCookie("auth-token") !== null && getCookie("auth-token") !== "" ?
                    isAuth.data !== undefined ?
                        isAuth.data.success === false &&
                            <AuthInterface onConnect={handleConnect}/>
                        :
                        isAuth.state === "error" ?
                        <AuthInterface onConnect={handleConnect}/>
                        :
                        <PageLoader text="auth.waitforauth"/>
                    :
                    <AuthInterface onConnect={handleConnect}/> // On lui met la page pour se connecter
            }
        </>
    )
}

export default AuthChecker;