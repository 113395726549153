import {forwardRef, useCallback, useImperativeHandle, useState} from "react";
import {PopupItem} from "./PopupItem"
import "./Popup.scss"
import { CSSTransition, TransitionGroup } from "react-transition-group";
const Popup = forwardRef((props, ref) =>{

    const [popupList, setPopupList] = useState([])

    useImperativeHandle(ref, () => ({
        addPopup (type, message, isMessageCustom = false) {
            setPopupList([
                {
                    "id": Math.random(),
                    "type": type,
                    "message": message,
                    "customMessage": isMessageCustom
                },
                ...popupList
            ])
        }
    }))

    const removePopup = useCallback((id) => {
        setPopupList(popupList.map((e) => {
            if(e.id !== id){
                return e;
            }
            return null;
        }).filter(e => e !== null))
    }, [popupList])

    return(
        <div className="popup">
            <TransitionGroup component={null}>
            {
                popupList.map((e) => (
                    <CSSTransition key={e.id} classNames="popup-anim" timeout={300}>
                        <PopupItem onRemove={removePopup} {...e} />
                    </CSSTransition>
                ))
            }
            </TransitionGroup>
        </div>
    )
})

export {Popup}