import { useContext, useMemo, useRef } from "react"
import { UserContext } from "App";
import { CSSTransition } from "react-transition-group"
import { Text } from "components/Common/Text"
import { Link } from "react-router-dom"
import Routes from "assets/routes.json"
import "./Navigation.scss"
import NavLink from "./NavLink";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faUser, faCalendarAlt, faScroll, faInfo, faHourglassHalf, faTools } from '@fortawesome/free-solid-svg-icons'

library.add(faHome, faUser, faCalendarAlt, faScroll, faInfo, faHourglassHalf, faTools)

const Navigation = ({isDeployed}) => {

    const { data: {login, permissions} } = useContext(UserContext)
    const Tref = useRef(null)

    const RoutesFiltered = useMemo(() => (
        JSON.parse(JSON.stringify(Routes)).filter(e => {return e.onNavBar !== false})
    ), [])

    return(
        <CSSTransition in={isDeployed} nodeRef={Tref} timeout={400} classNames={"navigation-animation"} >
            <div ref={Tref} className="navigation">
                <div className="navigation__padding">
                {
                    RoutesFiltered.map(({path, clicklink = path, permission, name , sub = null, icon = null, onnavbar = true}) => (
                        (login !==undefined && onnavbar && (permissions.includes(permission) || permission === null)) &&
                            <NavLink key={clicklink} name={name} link={typeof(clicklink) === "object" ? clicklink[0] : clicklink} icon={icon}>
                                {
                                    sub !== null &&
                                        sub.map(({name, path, clicklink = path}) =>(
                                           <Link key={name + clicklink} to={clicklink}>
                                               <Text request={"pathname." + name} />
                                           </Link>
                                    ))
                                }
                            </NavLink>
                        ))
                }
                </div>
            </div>
        </CSSTransition>
    )
}

export { Navigation }