import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {Success, Error} from "components/Common/StateIcon"
import {Text} from "components/Common/Text"
const PopupItem = ({message, type, id, onRemove, customMessage}) => {
    return(
        <div className={"popup__item " + type} onClick={() => onRemove(id)}>
            <div className="popup__item--icon">
                {
                    type === "error" &&
                        <Error />
                }
                {
                    type == "success" &&
                        <Success color="#fff" />
                }
            </div>
            <div className="popup__item--text">
                {customMessage ?
                    message
                    :
                    <Text request={'error.' + message} />
                }

            </div>
            <div className="popup__item--times">
                <FontAwesomeIcon icon='times' />
            </div>
        </div>
    )
}

export {PopupItem}