import './Title.scss';
import {Text} from "../";
const Title = ({text, title = text}) => {
    return (
        <div className="mainTitle">
            <Text request={title} />
        </div>
    );
}

export {Title}